import './App.css';

function App() {
  return (
    <div className="App">
      <div className="image-container">
        <div className="overlay"></div>
        <div className="content">
          <div className="footer">
            <div className="item">
              <a href="/privacyPolicy">Privacy Policy</a>
            </div>
            |
            <div className="item"><a href="/termsOfService">Terms of Service</a></div> | <div className="item"><a href="mailto:info@kan-guru.com">Contact
            Us</a></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
